

















import { Component, Mixins } from 'vue-property-decorator';
import { Office, MapSettings, MapMarker } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { Map } from 'client-website-ts-library/components';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Map,
    Form,
  },
})
export default class ContactUs extends Mixins(View) {
  private office: Office | null = null;

  private mapSettings: MapSettings | null = null;

  mounted() {
    API.Profiles.Get(Config.Website.Settings!.WebsiteId, true).then((profile) => {
      this.office = profile.Office;

      this.mapSettings = new MapSettings({
        Markers: [
          new MapMarker({
            Address: `${this.office.AddressLine1} ${this.office.AddressLine2}`,
            Title: this.office.FranchiseName,
            Coordinate: {
              Lat: -16.9069013,
              Lon: 145.7594106,
            },
          }),
        ],
      });

      console.debug(this.mapSettings);
    });
  }
}
